
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


let routes = [
	//Dashboard routes


//accesslog routes
			{
				path: '/accesslog/:index?/:fieldName?/:fieldValue?',
				name: 'accessloglist',
				component: () => import('./pages/accesslog/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/accesslog/view/:id', 
				name: 'accesslogview', 
				component: () => import('./pages/accesslog/view.vue'), 
				props: true
			},
	
			{ 
				path: '/accesslog/add', 
				name: 'accesslogadd', 
				component: () => import('./pages/accesslog/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accesslog/edit/:id', 
				name: 'accesslogedit', 
				component: () => import('./pages/accesslog/edit.vue'), 
				props: true
			},
		

//accessrequest_priorities routes
			{
				path: '/accessrequest_priorities/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequest_prioritieslist',
				component: () => import('./pages/accessrequest_priorities/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/accessrequest_priorities/view/:id', 
				name: 'accessrequest_prioritiesview', 
				component: () => import('./pages/accessrequest_priorities/view.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequest_priorities/add', 
				name: 'accessrequest_prioritiesadd', 
				component: () => import('./pages/accessrequest_priorities/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequest_priorities/edit/:id', 
				name: 'accessrequest_prioritiesedit', 
				component: () => import('./pages/accessrequest_priorities/edit.vue'), 
				props: true
			},
		

//accessrequest_status routes
			{
				path: '/accessrequest_status/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequest_statuslist',
				component: () => import('./pages/accessrequest_status/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/accessrequest_status/view/:id', 
				name: 'accessrequest_statusview', 
				component: () => import('./pages/accessrequest_status/view.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequest_status/add', 
				name: 'accessrequest_statusadd', 
				component: () => import('./pages/accessrequest_status/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequest_status/edit/:id', 
				name: 'accessrequest_statusedit', 
				component: () => import('./pages/accessrequest_status/edit.vue'), 
				props: true
			},
		

//accessrequests routes
			{
				path: '/accessrequests/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestslist',
				component: () => import('./pages/accessrequests/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/accessrequests/view/:id', 
				name: 'accessrequestsview', 
				component: () => import('./pages/accessrequests/view.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequests/add', 
				name: 'accessrequestsadd', 
				component: () => import('./pages/accessrequests/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequests/edit/:id', 
				name: 'accessrequestsedit', 
				component: () => import('./pages/accessrequests/edit.vue'), 
				props: true
			},
		
			{
				path: '/accessrequests/cardlistpage/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestscardlistpage',
				component: () => import('./pages/accessrequests/cardlistpage.vue'), 
				props: true
			},
			
	
			{
				path: '/accessrequests/board1/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestsboard1',
				component: () => import('./pages/accessrequests/board1.vue'), 
				props: true
			},
			
	
			{
				path: '/accessrequests/board2/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestsboard2',
				component: () => import('./pages/accessrequests/board2.vue'), 
				props: true
			},
			
	
			{
				path: '/accessrequests/board3/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestsboard3',
				component: () => import('./pages/accessrequests/board3.vue'), 
				props: true
			},
			
	
			{
				path: '/accessrequests/board4/:index?/:fieldName?/:fieldValue?',
				name: 'accessrequestsboard4',
				component: () => import('./pages/accessrequests/board4.vue'), 
				props: true
			},
			
	

//permissions routes
			{
				path: '/permissions/:index?/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//userdepts routes
			{
				path: '/userdepts/:index?/:fieldName?/:fieldValue?',
				name: 'userdeptslist',
				component: () => import('./pages/userdepts/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/userdepts/view/:id', 
				name: 'userdeptsview', 
				component: () => import('./pages/userdepts/view.vue'), 
				props: true
			},
	
			{ 
				path: '/userdepts/add', 
				name: 'userdeptsadd', 
				component: () => import('./pages/userdepts/add.vue'), 
				props: true
			},
	
			{ 
				path: '/userdepts/edit/:id', 
				name: 'userdeptsedit', 
				component: () => import('./pages/userdepts/edit.vue'), 
				props: true
			},
		

//userroles routes
			{
				path: '/userroles/:index?/:fieldName?/:fieldValue?',
				name: 'userroleslist',
				component: () => import('./pages/userroles/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/userroles/view/:id', 
				name: 'userrolesview', 
				component: () => import('./pages/userroles/view.vue'), 
				props: true
			},
	
			{ 
				path: '/userroles/add', 
				name: 'userrolesadd', 
				component: () => import('./pages/userroles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/userroles/edit/:id', 
				name: 'userrolesedit', 
				component: () => import('./pages/userroles/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:index?/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//userstatus routes
			{
				path: '/userstatus/:index?/:fieldName?/:fieldValue?',
				name: 'userstatuslist',
				component: () => import('./pages/userstatus/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/userstatus/view/:id', 
				name: 'userstatusview', 
				component: () => import('./pages/userstatus/view.vue'), 
				props: true
			},
	
			{ 
				path: '/userstatus/add', 
				name: 'userstatusadd', 
				component: () => import('./pages/userstatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/userstatus/edit/:id', 
				name: 'userstatusedit', 
				component: () => import('./pages/userstatus/edit.vue'), 
				props: true
			},
		

//vendors routes
			{
				path: '/vendors/:index?/:fieldName?/:fieldValue?',
				name: 'vendorslist',
				component: () => import('./pages/vendors/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/vendors/view/:id', 
				name: 'vendorsview', 
				component: () => import('./pages/vendors/view.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors/add', 
				name: 'vendorsadd', 
				component: () => import('./pages/vendors/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors/edit/:id', 
				name: 'vendorsedit', 
				component: () => import('./pages/vendors/edit.vue'), 
				props: true
			},
		

//vendors_levels routes
			{
				path: '/vendors_levels/:index?/:fieldName?/:fieldValue?',
				name: 'vendors_levelslist',
				component: () => import('./pages/vendors_levels/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/vendors_levels/view/:id', 
				name: 'vendors_levelsview', 
				component: () => import('./pages/vendors_levels/view.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors_levels/add', 
				name: 'vendors_levelsadd', 
				component: () => import('./pages/vendors_levels/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors_levels/edit/:id', 
				name: 'vendors_levelsedit', 
				component: () => import('./pages/vendors_levels/edit.vue'), 
				props: true
			},
		

//vendors_status routes
			{
				path: '/vendors_status/:index?/:fieldName?/:fieldValue?',
				name: 'vendors_statuslist',
				component: () => import('./pages/vendors_status/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/vendors_status/view/:id', 
				name: 'vendors_statusview', 
				component: () => import('./pages/vendors_status/view.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors_status/add', 
				name: 'vendors_statusadd', 
				component: () => import('./pages/vendors_status/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors_status/edit/:id', 
				name: 'vendors_statusedit', 
				component: () => import('./pages/vendors_status/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/board', 
				name: 'board', 
				component: () => import('././pages/custom/board.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			//if(savedPostion) return savedPostion;
			return { x:0, y:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
