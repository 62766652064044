
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-chart-pie text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/accessrequests",
    "label": "Solicitudes de Acceso",
    "icon": "pi pi-qrcode text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/vendors",
    "label": "Proveedores",
    "icon": "pi pi-briefcase text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/accesslog",
    "label": "Bitacora de Acceso",
    "icon": "pi pi-book text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Configuración",
    "icon": "pi pi-cog text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/users",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/userroles",
        "label": "Roles de Usuario",
        "icon": "pi pi-key",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/accessrequest_status",
        "label": "Catalogo de Solicitudes",
        "icon": "pi pi-bookmark",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/userdepts",
        "label": "Departamentos",
        "icon": "pi pi-sitemap",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/permissions",
        "label": "Permissions",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}